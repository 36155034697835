<template>
  <div
    class="user-profile-sidebar"
    :class="{'show': shallShowActiveChatContactSidebar}"
  >
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
      class="chat-app-window"
    >
      <header
        class="user-profile-header"
      >
        <span class="close-icon">
          <feather-icon
            icon="XIcon"
            @click="$emit('update:shall-show-active-chat-contact-sidebar', false)"
          />
        </span>

        <div class="header-profile-sidebar">
          <div class="mb-1">
            <b-avatar
              size="70"
              :text="avatarText(groupName)"
              :variant="badgeColor"
            />
          </div>
          <h4 class="chat-user-name">
            {{ groupName }}
          </h4>
          <b-badge
            class="user-post text-capitalize"
            :variant="badgeColor"
          >
            {{ activeChatroom.type }}
          </b-badge>
        </div>
      </header>

      <!-- User Details -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="user-profile-sidebar-area scroll-area"
      >

        <!-- Personal Info -->
        <div class="personal-info">
          <h6 class="section-label mb-1 mt-3 font-weight-bolder text-dark">
            Participants
          </h6>
          <ul class="list-unstyled">
            <li
              v-for="participant in participants"
              :key="participant.id"
              class="mb-1 d-flex justify-content-between align-items-center"
            >
              <div class="mb-1 d-flex justify-content-left align-items-center">
                <b-avatar
                  :text="avatarText(`${participant.first_name} ${participant.last_name}`)"
                  size="36"
                  variant="light-primary"
                  class="mr-1 badge-minimal"
                  badge
                />
                <h6 class="mb-0">
                  {{ `${participant.first_name} ${participant.last_name}` }}
                </h6>
              </div>

              <div
                v-if="store.getters.permissions.filter(permission => permission === 'edit-chatrooms').length > 0 && activeChatroom.type !== 'Private' && participant.id != currentUser.id"
              >
                <b-dropdown
                  no-caret
                  toggle-class="text-decoration-none"
                  class="dropdown-no-scroll"
                  variant="link"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      class="text-body mb-1"
                      icon="MoreVerticalIcon"
                      size="12"
                    />
                  </template>
                  <b-dropdown-item
                    @click="removeChatroomParticipant(participant)"
                  >
                    <feather-icon icon="LogOutIcon" />
                    Remove
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </li>
          </ul>
        </div>

        <!-- More Options -->
        <div class="more-options">
          <h6 class="section-label mb-1 mt-3 font-weight-bolder text-dark">
            Options
          </h6>
          <ul class="list-unstyled">
            <b-button
              v-if="store.getters.permissions.filter(permission => permission === 'edit-chatrooms').length > 0 && store.getters.permissions.filter(permission => permission === 'view-staff').length > 0 && activeChatroom.type !== 'Private'"
              class="cursor-pointer pl-1 w-100 mb-1"
              variant="success"
              @click="openAddParticipantModal()"
            >
              <feather-icon
                icon="UserPlusIcon"
                size="16"
                class="mr-75"
              />
              <span class="align-middle">Add Participant</span>
            </b-button>
            <b-button
              class="cursor-pointer pl-1 w-100"
              variant="danger"
              @click="$emit('leave-chatroom', group)"
            >
              <feather-icon
                icon="LogOutIcon"
                size="16"
                class="mr-75"
              />
              <span class="align-middle">Leave Chatroom</span>
            </b-button>
          </ul>
        </div>
      </vue-perfect-scrollbar>
    </b-overlay>
    <chatroom-update-modal ref="chatroom_update_modal" />
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BAvatar, BBadge, BButton, BOverlay, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { avatarText } from '@core/utils/filter'
import ChatroomUpdateModal from '@/components/Chat/ChatroomUpdateModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ChatRepository = RepositoryFactory.get('chat')

export default {
  components: {
    BBadge,
    BAvatar,
    BButton,
    BOverlay,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    ChatroomUpdateModal,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    activeChatroom: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      store,
      avatarText,
      loading: false,
      participants: [],
    }
  },
  computed: {
    groupName() {
      return this.activeChatroom.type === 'Private' ? this.activeChatroom.name.replaceAll(`${this.currentUser.first_name} ${this.currentUser.last_name}`, '').replaceAll(',', '').trim() : this.activeChatroom.name
    },
    badgeColor() {
      // eslint-disable-next-line no-nested-ternary
      return this.activeChatroom.type === 'Open' ? 'light-success' : this.activeChatroom.type === 'Group' ? 'light-warning' : 'light-info'
    },
  },
  watch: {
    shallShowActiveChatContactSidebar(val) {
      if (val) this.getChatroomParticipants()
    },
  },
  methods: {
    async getChatroomParticipants() {
      try {
        this.loading = true
        const { data } = (await ChatRepository.getChatroomParticipants(this.activeChatroom.id)).data
        this.participants = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async removeChatroomParticipant(participant) {
      try {
        this.loading = true
        const payload = {
          participant_id: participant.id,
          participant_type: participant.type,
        }
        const res = await ChatRepository.removeChatroomParticipant(this.activeChatroom.id, payload)
        if (res.status === 200) {
          this.showSuccessMessage('Participant removed successfully')
          this.getChatroomParticipants()
        } else {
          this.showErrorMessage('Failed to remove participant')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    openAddParticipantModal() {
      this.$refs.chatroom_update_modal.openModal(this.activeChatroom, 'Update')
    },
  },
}
</script>

<style>

</style>
