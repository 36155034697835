<template>
  <b-modal
    id="add-chatroom-participant"
    ref="modal"
    v-model="open"
    size="md"
    no-close-on-backdrop
    hide-footer
    centered
    :title="modalTitle"
  >
    <div>
      <b-overlay
        id="overlay-background"
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-form
          @submit.prevent="type === 'Add' ? createChatroom() : addChatroomParticipant()"
        >
          <b-row
            v-if="type === 'Add'"
          >
            <b-col md="12">
              <b-form-group
                label-for="vi-group_name"
              >
                <b-form-input
                  id="vi-group_name"
                  v-model="groupName"
                  placeholder="Enter Group Name"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <v-select
                v-model="selectedUsers"
                :options="userList"
                :reduce="option => option.id"
                label="first_name"
                placeholder="Select Participants"
                multiple
                deselect-from-dropdown
              >
                <template v-slot:option="option">
                  {{ option.first_name }} {{ option.last_name }}
                </template>
                <template #search="{attributes}">
                  <input
                    class="vs__search"
                    style="pointer-events:none"
                    :required="selectedUsers.length === 0"
                    v-bind="attributes"
                  >
                </template>
              </v-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                size="md"
                class="w-100 mt-1"
                type="submit"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  style="margin-right:5px;"
                />
                <span class="align-middle">{{ type === 'Add'? 'Create Group' : 'Add Participant' }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BButton,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const ChatRepository = RepositoryFactory.get('chat')

function initialState() {
  return {
    open: false,
    loading: false,
    chatroom: {
      name: '',
    },
    userList: [],
    selectedUsers: [],
    groupName: '',
    type: 'Update',
    currentUser: {},
  }
}

export default {
  components: {
    BOverlay,
    BRow,
    vSelect,
    BCol,
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    modalTitle() {
      if (this.type === 'Update') return `ADD PARTICIPANT TO CHATROOM - ${this.chatroom.name.toUpperCase()}`
      return 'CREATE GROUP'
    },
  },
  methods: {
    async openModal(chatroom, type) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.type = type
      this.chatroom = chatroom
      this.currentUser = JSON.parse(localStorage.getItem('current_user'))
      this.readUserList()
    },
    async readUserList() {
      try {
        this.loading = true
        const { data } = (await ResourceRepository.getAllStaffListNoPagination()).data
        this.userList = data
        this.userList = this.userList.filter(user => user.id !== this.currentUser.id)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async addChatroomParticipant() {
      try {
        this.loading = true
        const payload = {
          participant_id: this.selectedUsers,
          participant_type: 'staff',
        }
        const res = await ChatRepository.addChatroomParticipant(this.chatroom.id, payload)
        if (res.status === 200) {
          this.showSuccessMessage('Participant added successfully')
          this.open = false
          this.$parent.getChatroomParticipants()
        } else {
          this.showErrorMessage('Failed to add participant')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async createChatroom() {
      try {
        this.loading = true
        const payload = {
          name: this.groupName,
          participant_id: this.selectedUsers,
          participant_type: 'staff',
          type: 'Group',
        }
        const res = await ChatRepository.createChatroom(payload)
        if (res.status === 201 || res.status === 200) {
          this.showSuccessMessage('Group created successfully')
          this.open = false
          this.$parent.groupChats.push(res.data.data)
        } else {
          this.showErrorMessage('Failed to create group')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
</style>
