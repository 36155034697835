<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChats"
      :key="msgGrp.sender.id+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.sender.id !== currentUser.id}"
    >
      <div
        v-if="!msgGrp.messages[0].msg.includes('has left this chatroom')"
        class="chat-avatar"
      >
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="primary"
          :text="avatarText(`${msgGrp.sender.first_name} ${msgGrp.sender.last_name}`)"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="msgData in msgGrp.messages"
          :key="msgData.time"
          :class="msgData.msg.includes('has left this chatroom') ? 'chat-system-message' : 'chat-content'"
        >
          <p>{{ msgData.msg }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      avatarText,
      currentUser: {},
      formattedChats: [],
    }
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('current_user'))
    this.formattedChats = []
    this.chatData.forEach((msg, index) => {
      if (index === 0) {
        this.formattedChats.push({
          sender: msg.user,
          messages: [{
            msg: msg.message.toString(),
            time: msg.created_at,
          }],
        })
      } else {
        // eslint-disable-next-line no-lonely-if
        if (msg.user.id === this.formattedChats[this.formattedChats.length - 1].sender.id) {
          this.formattedChats[this.formattedChats.length - 1].messages.push({
            msg: msg.message.toString(),
            time: msg.created_at,
          })
        } else {
          this.formattedChats.push({
            sender: msg.user,
            messages: [{
              msg: msg.message.toString(),
              time: msg.created_at,
            }],
          })
        }
      }
    })
  },
}
</script>

<style>

</style>
