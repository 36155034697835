<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=false"
    />
    <b-overlay
      :show="chatsLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
      class="chat-app-window"
    >
      <section class="chat-app-window">

        <div
          v-if="!activeChatroom.id"
          class="start-chat-area"
        >
          <div class="mb-1 start-chat-icon">
            <feather-icon
              icon="MessageSquareIcon"
              size="56"
            />
          </div>
          <h4
            class="sidebar-toggle start-chat-text"
            @click="startConversation"
          >
            Start Conversation
          </h4>
        </div>

        <div
          v-else
          class="active-chat"
        >
          <div class="chat-navbar">
            <header class="chat-header">

              <div class="d-flex align-items-center">

                <div class="sidebar-toggle d-block d-lg-none mr-1">
                  <feather-icon
                    icon="MenuIcon"
                    class="cursor-pointer"
                    size="21"
                    @click="mqShallShowLeftSidebar = true"
                  />
                </div>

                <b-avatar
                  :text="avatarText(activeChatroomName)"
                  size="36"
                  :variant="activeChatroom.type === 'Open' ? 'light-success' : activeChatroom.type === 'Open' ? 'light-warning' : 'light-primary'"
                  class="mr-1 cursor-pointer badge-minimal"
                  badge
                  @click.native="shallShowActiveChatContactSidebar=true"
                />
                <h6 class="mb-0">
                  {{ activeChatroomName }}
                </h6>
              </div>

              <div class="d-flex align-items-center">
                <div class="dropdown">
                  <b-dropdown
                    v-if="store.getters.permissions.filter(permission => permission === 'delete-chatrooms' || permission === 'clear-chatrooms').length > 0"
                    variant="link"
                    no-caret
                    class="dropdown-no-scroll"
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="17"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="store.getters.permissions.filter(permission => permission === 'delete-chatrooms').length > 0"
                      @click="deleteChatroom"
                    >
                      Delete Chatroom
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="store.getters.permissions.filter(permission => permission === 'clear-chatrooms').length > 0"
                      @click="clearActiveChatroom"
                    >
                      Clear Chat
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </header>
          </div>

          <vue-perfect-scrollbar
            ref="refChatLogPS"
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
          >
            <chat-log
              :key="activeChat.length"
              :chat-data="activeChat"
              :profile-user-avatar="12"
            />
          </vue-perfect-scrollbar>

          <b-form
            v-if="store.getters.permissions.filter(permission => permission === 'create-chats').length > 0"
            class="chat-app-form"
            @submit.prevent="sendMessage"
          >
            <b-input-group class="input-group-merge form-send-message mr-1">
              <b-form-input
                v-model="chatInputMessage"
                placeholder="Enter your message"
              />
            </b-input-group>
            <b-button
              variant="primary"
              type="submit"
            >
              Send
            </b-button>
          </b-form>
        </div>
      </section>
    </b-overlay>

    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :active-chatroom="activeChatroom"
      :current-user="currentUser"
      @leave-chatroom="leaveChatroom"
    />

    <portal to="content-renderer-sidebar-left">
      <b-overlay
        :show="contactsLoading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <chat-left-sidebar
          :key="privateChats.length"
          :group-chats="groupChats"
          :private-chats="privateChats"
          :active-chatroom-id="Number(activeChatroom.id)"
          :profile-user-data="profileUserData"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
          @open-chat="openChatOfContact"
        />
      </b-overlay>
    </portal>

  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import store from '@/store'
import {
  ref, nextTick,
} from '@vue/composition-api'
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton, BOverlay,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { avatarText } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import ChatLeftSidebar from '@/components/Chat/ChatLeftSidebar.vue'
import ChatLog from '@/components/Chat/ChatLog.vue'
import ChatActiveChatContentDetailsSidedbar from '@/components/Chat/ChatActiveChatContentDetailsSidedbar.vue'
import socket from '@/libs/socket.io'

const ChatRepository = RepositoryFactory.get('chat')

export default {
  components: {
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BOverlay,
    VuePerfectScrollbar,
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  mixins: [SuccessMessage, ErrorMessage],
  setup() {
    const chatInputMessage = ref('')

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Single Chat
      chatInputMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
  data() {
    return {
      store,
      socket,
      avatarText,
      activeChatroom: {},
      activeChat: [],
      contactsLoading: false,
      chatsLoading: false,
      groupChats: [],
      privateChats: [],
      currentUser: {},
    }
  },
  computed: {
    activeChatroomName() {
      return this.activeChatroom.type === 'Private' ? this.activeChatroom.name.replaceAll(`${this.currentUser.first_name} ${this.currentUser.last_name}`, '').replaceAll(',', '').trim() : this.activeChatroom.name
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('current_user'))
  },
  async mounted() {
    if (this.$route.params.chatroomId) this.activeChatroom.id = this.$route.params.chatroomId
    await this.fetchGroupChats()
    await this.fetchPrivateChats()
    if (this.$route.params.chatroomId) this.openChatOfContact(this.activeChatroom)
    this.initMessageListener()
  },
  methods: {
    async fetchGroupChats() {
      try {
        this.contactsLoading = true
        const { data } = (await ChatRepository.getChatrooms('filter[open]=true')).data
        this.groupChats = data
        this.groupChats.forEach(chatroom => {
          if (Number(this.activeChatroom.id) === chatroom.id) this.activeChatroom = chatroom
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.contactsLoading = false
    },
    async fetchPrivateChats() {
      try {
        this.contactsLoading = true
        const { data } = (await ChatRepository.getChatrooms('filter[type]=Private')).data
        this.privateChats = data
        this.privateChats.forEach(chatroom => {
          if (Number(this.activeChatroom.id) === chatroom.id) this.activeChatroom = chatroom
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.contactsLoading = false
    },
    async openChatOfContact(chatroom) {
      this.chatInputMessage = ''
      if (this.activeChatroom.id) {
        socket.emit('leaveRoom', this.activeChatroom.id)
      }
      this.activeChatroom = chatroom
      socket.emit('joinRoom', this.activeChatroom.id)
      try {
        this.chatsLoading = true
        const { data } = (await ChatRepository.getChatroomChatsNoPagination(chatroom.id)).data
        this.activeChat = data
        nextTick(() => { this.scrollToBottomInChatLog() })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.chatsLoading = false

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      this.mqShallShowLeftSidebar = false
    },
    async sendMessage() {
      try {
        this.chatsLoading = true
        const payload = {
          message: this.chatInputMessage,
        }
        const res = await ChatRepository.sendMessage(this.activeChatroom.id, payload)
        if (res.status === 200) {
          this.chatInputMessage = ''
          if (!payload.message.includes('has left this chatroom')) {
            this.updateLastMessage(payload.message)
            this.activeChat.push({
              id: res.data.id,
              message: payload.message,
              user: this.currentUser,
              created_at: res.data.created_at,
            })
            nextTick(() => { this.scrollToBottomInChatLog() })
          }
          socket.emit('chatroomMessage', {
            chatroom_id: this.activeChatroom.id.toString(),
            message: payload.message,
          })
        } else {
          this.showErrorMessage('Failed to send message')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.chatsLoading = false
    },
    async clearActiveChatroom() {
      try {
        this.chatsLoading = true
        const res = await ChatRepository.clearChatroomChats(this.activeChatroom.id)
        if (res.status === 200) {
          this.showSuccessMessage('Chatroom cleared successfully')
          this.updateLastMessage()
          await this.openChatOfContact(this.activeChatroom)
        } else {
          this.showErrorMessage('Failed to clear chatroom')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.chatsLoading = false
    },
    async deleteChatroom() {
      try {
        this.chatsLoading = true
        const res = await ChatRepository.deleteChatroom(this.activeChatroom.id)
        if (res.status === 200) {
          this.showSuccessMessage('Chatroom deleted successfully')
          this.updateChatGroups()
        } else {
          this.showErrorMessage('Failed to delete chatroom')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.chatsLoading = false
    },
    async leaveChatroom() {
      try {
        this.chatsLoading = true
        const payload = {
          participant_id: this.currentUser.id,
          participant_type: 'staff',
        }
        const res = await ChatRepository.removeChatroomParticipant(this.activeChatroom.id, payload)
        if (res.status === 200) {
          this.shallShowActiveChatContactSidebar = false
          this.showSuccessMessage('You have left the chatroom successfully')
          this.chatInputMessage = `${this.currentUser.first_name} ${this.currentUser.last_name} has left this chatroom`
          this.sendMessage()
          this.updateChatGroups()
        } else {
          this.showErrorMessage('Failed to delete chatroom')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.chatsLoading = false
    },
    async updateLastMessage(message) {
      this.activeChatroom.lastMessage = message ? {
        message,
      } : null
      let chatGroups = this.activeChatroom.type === 'Private' ? this.privateChats : this.groupChats
      chatGroups = chatGroups.map(chat => {
        if (chat.id === this.activeChatroom.id) {
          if (message) {
            chat.lastMessage = this.activeChatroom.lastMessage
            chat.created_at = 'today'
          } else {
            chat.lastMessage = null
          }
        }
        return chat
      })
      if (this.activeChatroom.type === 'Private') this.privateChats = chatGroups
      else this.groupChats = chatGroups
    },
    updateChatGroups() {
      if (this.activeChatroom.type === 'Private') this.privateChats = this.privateChats.filter(chatroom => chatroom.id !== this.activeChatroom.id)
      else this.groupChats = this.groupChats.filter(chatroom => chatroom.id !== this.activeChatroom.id)
      this.activeChatroom = {}
    },
    scrollToBottomInChatLog() {
      if (this.$refs.refChatLogPS) {
        const scrollEl = this.$refs.refChatLogPS.$el
        scrollEl.scrollTop = scrollEl.scrollHeight
      }
    },
    initMessageListener() {
      socket.on('newMessage', message => {
        if (message.user.id !== this.currentUser.id && !message.user.merchant_id) {
          this.activeChat.push(message)
          nextTick(() => { this.scrollToBottomInChatLog() })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
