<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      :text="avatarText(groupName)"
      size="42"
      :variant="group.type === 'Open' ? 'light-success' : group.type === 'Group' ? 'light-warning' : 'light-info'"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ groupName }}
      </h5>
      <p class="card-text text-truncate">
        {{ group.lastMessage ? group.lastMessage.message : 'Start a conversation' }}
      </p>
    </div>
    <div
      class="chat-meta text-nowrap"
    >
      <div
        v-if="group.lastMessage"
      >
        <small class="float-right mb-25 chat-time">{{ formatDateToMonthShort(group.lastMessage.created_at, { hour: 'numeric', minute: 'numeric' }) }}</small>
      </div>
      <b-badge
        v-if="group.unseenMsgs"
        pill
        variant="primary"
        class="float-right"
      >
        {{ group.unseenMsgs }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { avatarText, formatDateToMonthShort } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    group: {
      type: Object,
      required: true,
      default: () => ({
        lastMessage: {},
      }),
    },
  },
  setup() {
    return { formatDateToMonthShort }
  },
  data() {
    return {
      avatarText,
      currentUser: {},
    }
  },
  computed: {
    groupName() {
      return this.group.type === 'Private' ? this.group.name.replaceAll(`${this.currentUser.first_name} ${this.currentUser.last_name}`, '').replaceAll(',', '').trim() : this.group.name
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('current_user'))
  },
}
</script>

<style>

</style>
