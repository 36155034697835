import Vue from 'vue'
import io from 'socket.io-client'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cookies from 'js-cookie'
import { socketServerUrl, tenant } from '@/constants/config'

const cacheUserToken = Cookies.get('current_user_token')

const socket = io(socketServerUrl, {
  transports: ['websocket'],
  query: {
    'x-tenant': tenant,
    token: cacheUserToken,
  },
})

socket.on('error', err => {
  Vue.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: 'Error',
      icon: 'XIcon',
      variant: 'danger',
      text: err,
    },
  }, { timeout: 15000 })
})

export default socket
