<template>
  <div class="sidebar-left">
    <div class="sidebar">

      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <div class="sidebar-profile-toggle">
              <b-avatar
                size="42"
                class="cursor-pointer badge-minimal avatar-border-2"
                src="@/assets/images/avatars/12-small.png"
                variant="transparent"
                badge
                badge-variant="success"
              />
            </div>
            <!-- Search -->
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search..."
              />
            </b-input-group>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
          style="height: calc(100vh - 230px);"
        >

          <!-- Chats Title -->
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="chat-list-title">
              Spaces
            </h4>
            <div
              v-if="store.getters.permissions.filter(permission => permission === 'create-chatrooms').length > 0"
              @click="onClickAddGroup"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="text-primary mt-1 mr-2 cursor-pointer"
                size="25"
              />
            </div>
          </div>

          <div
            v-if="filteredGroupChats.length === 0"
          >
            <div class="text-center mx-1 shadow rounded bg-light-danger">
              <div
                class="font-weight-bold"
                style="padding-top:10px;padding-bottom:10px;"
              >
                No group chats
              </div>
            </div>
          </div>

          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list">
            <chat-contact
              v-for="group in filteredGroupChats"
              :key="group.id"
              :group="group"
              tag="li"
              :class="{'active': activeChatroomId === group.id}"
              @click="$emit('open-chat', group)"
            />
          </ul>

          <h4 class="chat-list-title">
            Private
          </h4>

          <div
            v-if="filteredPrivateChats.length === 0"
          >
            <div class="text-center mx-1 shadow rounded bg-light-danger">
              <div
                class="font-weight-bold"
                style="padding-top:10px;padding-bottom:10px;"
              >
                No private chats
              </div>
            </div>
          </div>

          <ul class="chat-users-list contact-list media-list">
            <chat-contact
              v-for="private_chat in filteredPrivateChats"
              :key="private_chat.id"
              :group="private_chat"
              :class="{'active': activeChatroomId === private_chat.id}"
              tag="li"
              @click="$emit('open-chat', private_chat)"
            />
          </ul>
        </vue-perfect-scrollbar>
      </div>

    </div>
    <chatroom-update-modal ref="chatroom_update_modal" />
  </div>
</template>

<script>
import {
  BAvatar, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatroomUpdateModal from '@/components/Chat/ChatroomUpdateModal.vue'
import ChatContact from './ChatContact.vue'

export default {
  components: {
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    VuePerfectScrollbar,
    ChatContact,
    ChatroomUpdateModal,
  },
  props: {
    groupChats: {
      type: Array,
      required: true,
    },
    privateChats: {
      type: Array,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    activeChatroomId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      store,
      searchQuery: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      searchFilterFunction: space => space.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
    }
  },
  computed: {
    filteredGroupChats() {
      return this.groupChats.filter(this.searchFilterFunction)
    },
    filteredPrivateChats() {
      return this.privateChats.filter(this.searchFilterFunction)
    },
  },
  methods: {
    onClickAddGroup() {
      this.$refs.chatroom_update_modal.openModal(null, 'Add')
    },
  },
}
</script>
